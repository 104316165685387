import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import React from 'react';

const App = () => {
    return (
        <Router>
          <Switch>
            <Route exact path='/' component={XD} />
            <Route exact path='/STY54321FA18' component={XD} />
            <Route exact path='/survey' component={RedirectSurvey} />
            <Route component={NoMatch} />
          </Switch>
        </Router>
    );
};

const NoMatch = ({ location }) => (
  <pre>No match for {location.pathname}</pre>
)

const XD = () => { 
  return (
    <embed src="https://xd.adobe.com/embed/cf5e6c60-699a-4825-b6c8-878604ff4b94-9a0d/?fullscreen&hints=off" width="100%" height="100%"/>
  )
}

const RedirectSurvey = () => {
  return (
    <embed src="https://docs.google.com/forms/d/e/1FAIpQLSdaBYCp4IYpS5LYa2Ojugw-iJNRg1yTeCSS6b-mepqs27T8GQ/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" />
  )
}



export default App;